import React, { useEffect, useState } from "react";

import { message, List, Button } from "antd";
import request from "../../tools/request";
import { getPathInfo, setPathInfo } from "../../tools/config";
import { useHistory } from "react-router-dom";
import { RUNTIME } from "../../config";
export default () => {
  const history = useHistory();
  const [itimeList, setItimeList] = useState({
    data: [],
    last_page: 0,
    per_page: 20,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getTimeList(page);
  }, [page]);
  function getTimeList(page) {
    setIsLoading(true);
    request("get", "getTimeList", {
      id: getPathInfo().ku_id,
      page,
    }).then((res) => {
      const { code, msg } = res;
      if (code === 200) setItimeList(msg);
      else message.warning(msg);
      setIsLoading(false);
    });
  }

  function listItemHandle(itime_id) {
    setPathInfo({ itime_id });
    history.push(`/cang`);
  }
  return (
    <>
      <Button
        type="primary"
        onClick={() => listItemHandle("11111111111111111111111111111111")}
      >
        {RUNTIME.GETNEWDATA}
      </Button>
      <List
        loading={isLoading}
        dataSource={itimeList.data}
        renderItem={(item) => (
          <List.Item
            extra={
              <Button
                type="link"
                size="small"
                onClick={() => listItemHandle(item.id)}
              >
                {RUNTIME.ENTER}
              </Button>
            }
          >
            {item.itime}
          </List.Item>
        )}
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: itimeList.per_page,
          current: page,
          total: itimeList.total,
          size: "small",
        }}
      />
    </>
  );
};
