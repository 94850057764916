import React from "react";
import { Space, Spin, Typography } from "antd";
import { getAlterColor } from "../../tools/tool";
import { RUNTIME } from "../../config";

export default (props) => {
  if (!props.data) return <Spin />;
  const { base, temp, ext } = props.data;
  let ww = null; //外温
  let ws = null; //外湿
  if (ext && ext.data) {
    const json = JSON.parse(ext.data);
    ww = json.外温;
    ws = json.外湿;
  }
  return (
    <>
      <Space direction="vertical">
        <Typography.Text
          style={{ color: getAlterColor(temp.temp_max) }}
        >{`${RUNTIME.HIGHTEMP}:${temp.temp_max}°C (${temp.temp_max_lan}${RUNTIME.LAN}${temp.temp_max_ceng}${RUNTIME.CENG})`}</Typography.Text>
        <Typography.Text
          style={{ color: getAlterColor(temp.temp_min) }}
        >{`${RUNTIME.LOWTEMP}:${temp.temp_min}°C (${temp.temp_min_lan}${RUNTIME.LAN}${temp.temp_min_ceng}${RUNTIME.CENG})`}</Typography.Text>
        <Typography.Text
          style={{ color: getAlterColor(temp.temp_avg) }}
        >{`${RUNTIME.AVGTEMP}:${temp.temp_avg}°C`}</Typography.Text>

        {ww === null ? (
          <></>
        ) : (
          <Typography.Text
            style={{ color: getAlterColor(ww) }}
          >{`${RUNTIME.OUTTEMP}:${ww}°C`}</Typography.Text>
        )}
        {ws === null ? (
          <></>
        ) : (
          <Typography.Text
            style={{ color: getAlterColor(ws) }}
          >{`${RUNTIME.OUTHUM}:${ws}°C`}</Typography.Text>
        )}

        <Typography.Text>{`${RUNTIME.TIME}:${base.itime}`}</Typography.Text>
      </Space>
    </>
  );
};
