import React, { useEffect, useState } from "react";

import { message, List, Button } from "antd";
import request from "../../tools/request";
import { useHistory } from "react-router-dom";
import { getPathInfo, setPathInfo } from "../../tools/config";
import { RUNTIME } from "../../config";
export default () => {
  const [itimeList, setItimeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  useEffect(() => getCangList(), []);

  const getCangList = () => {
    request("get", "getCangList", { id: getPathInfo().itime_id }).then(
      (res) => {
        const { code, msg } = res;
        if (code === 200) setItimeList(msg);
        else message.warning(msg);
        setIsLoading(false);
      }
    );
  };
  function listItemHandle(cang_id) {
    setPathInfo({ cang_id });
    history.push(`/data`);
  }
  return (
    <>
      <List
        loading={isLoading}
        dataSource={itimeList}
        renderItem={(item) => (
          <List.Item
            extra={
              <Button
                type="link"
                size="small"
                onClick={() => listItemHandle(item.cang)}
              >
                {RUNTIME.ENTER}
              </Button>
            }
          >
            {item.cang}
          </List.Item>
        )}
      />
    </>
  );
};
