import React from "react";
import MainHeader from "./MainHeader";
import MainBody from "./MainBody";
import RcQueueAnim from "rc-queue-anim";
import "./index.css";

export default () => {
  return (
    <>
      <div className="main-header">
        <MainHeader />
      </div>
      <RcQueueAnim>
        <div key="main-body" className="main-body">
          <MainBody />
        </div>
      </RcQueueAnim>
    </>
  );
};
