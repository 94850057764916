import React, { useEffect, useState } from "react";

import { message, List, Button } from "antd";
import request from "../../tools/request";
import { setPathInfo } from "../../tools/config";
import { useHistory } from "react-router-dom";
import { RUNTIME } from "../../config";
export default () => {
  const history = useHistory();
  const [subComList, setSubComList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getSubComList();
  }, []);
  const getSubComList = () => {
    request("get", "getSubComList").then((res) => {
      const { code, msg } = res;
      if (code === 200) setSubComList(msg);
      else message.warning(msg);
      setIsLoading(false);
    });
  };

  const listItemHandle = (ku_id) => {
    setPathInfo({ ku_id });
    history.push(`/itime`);
  };
  return (
    <>
      <List
        loading={isLoading}
        dataSource={subComList}
        renderItem={(item) => (
          <List.Item
            extra={
              <Button
                type="link"
                size="small"
                onClick={() => listItemHandle(item.id)}
              >
                {RUNTIME.ENTER}
              </Button>
            }
          >
            {item.name}
          </List.Item>
        )}
      />
    </>
  );
};
