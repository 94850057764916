import React, { useEffect } from "react";
import { Button, Card, Form, Input, message, PageHeader } from "antd";
import { useHistory } from "react-router-dom";
import cookie from "react-cookies";
import RcQueueAnim from "rc-queue-anim";
import { getSetting, setSetting } from "../../tools/config";
import { RUNTIME } from "../../config";

export default () => {
  const history = useHistory();
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(getSetting());
  }, []);
  return (
    <>
      <PageHeader
        onBack={() => history.goBack()}
        title={RUNTIME.SETTING}
        extra={[
          <Button
            key="save"
            type="link"
            size="small"
            onClick={() => form.submit()}
          >
            {RUNTIME.SAVE}
          </Button>,
        ]}
      >
        <RcQueueAnim>
          <Form
            key="form"
            form={form}
            size="small"
            onFinish={(value) => {
              setSetting(value);
              message.success("ok");
              history.goBack();
            }}
          >
            <Card title={RUNTIME.TEMPALERT}>
              <Form.Item name="temp_alert_max">
                <Input addonBefore={RUNTIME.UPPERLIMIT} addonAfter="°C" />
              </Form.Item>
              <Form.Item name="temp_alert_min">
                <Input addonBefore={RUNTIME.LOWERLIMIT} addonAfter="°C" />
              </Form.Item>
            </Card>
          </Form>

          <Button
            key="logout"
            style={{ marginTop: 16 }}
            type="primary"
            danger
            block
            onClick={() => {
              cookie.remove("Authorization");
              history.push("/login");
            }}
          >
            {RUNTIME.LOGOUT}
          </Button>
        </RcQueueAnim>
      </PageHeader>
    </>
  );
};
