import React from "react";
import { PageHeader, Form, Input, Button, Card, message } from "antd";
import request from "../../tools/request";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";
import { RUNTIME } from "../../config";

export default () => {
  const history = useHistory();
  const loginHandle = (values) => {
    request("post", "login", values).then((res) => {
      const { code, msg } = res;
      if (code !== 200) {
        message.warning(msg);
        return;
      }
      localStorage.removeItem("pathInfo");
      cookie.save("Authorization", msg);
      history.push("/");
    });
  };
  return (
    <>
      <PageHeader title={RUNTIME.LANGFANGZHAOSUI} />
      <Card style={{ margin: "32px auto", maxWidth: 320 }} bordered={false}>
        <Form onFinish={(values) => loginHandle(values)}>
          <Form.Item name="com">
            <Input placeholder={RUNTIME.COMPANY} />
          </Form.Item>
          <Form.Item name="subCom">
            <Input placeholder={RUNTIME.SUBCOMPANY} />
          </Form.Item>
          <Form.Item name="user">
            <Input placeholder={RUNTIME.USERNAME} />
          </Form.Item>
          <Form.Item name="pwd">
            <Input placeholder={RUNTIME.PASSWORD} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block shape="round">
              {RUNTIME.LOGIN}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="default"
              block
              shape="round"
              onClick={() => {
                loginHandle({
                  com: "guest",
                  user: "123456",
                  pwd: "123456",
                });
              }}
            >
              {RUNTIME.LOGINGUEST}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
