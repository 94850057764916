import React from "react";
import Cang from "../../components/Cang";
import Itime from "../../components/Itime";
import Ku from "../../components/Ku";
import { Route, Switch } from "react-router-dom";

export default () => {
  return (
    <>
      <Switch>
        <Route path="/" exact component={Ku} />
        <Route path="/itime" exact component={Itime} />
        <Route path="/cang" exact component={Cang} />
      </Switch>
    </>
  );
};
