import React, { useEffect, useState } from "react";
import { message, Button, PageHeader, Popover, Input, Modal, Form } from "antd";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  alertColors,
  getPathInfo,
  getSetting,
  setSetting,
} from "../../tools/config";
import "./index.css";
import DataPopover from "../../components/DataPopover";
import DataDraw from "../../components/DataDraw";
import DataTable from "../../components/DataTable";
import request from "../../tools/request";
import { SettingOutlined } from "@ant-design/icons";
import { RUNTIME } from "../../config";

export default () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [isRefresh, setIsRefresh] = useState(0);
  const [size, setSize] = useState(1);

  useEffect(() => {
    getData();
  }, []);

  //最小8列 4行
  //

  const getData = () => {
    const json = getPathInfo();
    const { itime_id, cang_id } = json;
    request("get", "getData", {
      id: itime_id,
      cang: cang_id,
    }).then((res) => {
      const { code, msg } = res;
      if (code !== 200) {
        message.warning(msg);
        return;
      }
      setData(msg);
    });
  };

  return (
    <>
      <div className="data-header">
        <PageHeader
          onBack={() => history.push("/cang")}
          title={RUNTIME.DATAVIEW}
          extra={[
            <Button
              key="bigger"
              size="small"
              onClick={() => setSize(size + 0.1)}
            >
              {RUNTIME.ZOOMIN}
            </Button>,
            <Button
              key="smaller"
              size="small"
              onClick={() => setSize(size - 0.1)}
            >
              {RUNTIME.ZOOMOUT}
            </Button>,
            <div key="alert" style={{ display: "inline-block" }}>
              <b
                style={{
                  color: alertColors[0],
                  marginRight: 4,
                }}
              >
                {getSetting().temp_alert_min || 10}
              </b>
              {alertColors.map((item, key) => (
                <div
                  key={key}
                  style={{
                    display: "inline-block",
                    height: 12,
                    width: 12,
                    backgroundColor: item,
                  }}
                />
              ))}
              <b
                style={{
                  color: alertColors[alertColors.length - 1],
                  marginLeft: 4,
                }}
              >
                {getSetting().temp_alert_max || 30}
              </b>
            </div>,
            <Button
              key="setting"
              size="small"
              icon={<SettingOutlined />}
              onClick={() => {
                form.setFieldsValue(getSetting());
                setIsModal(true);
              }}
            >
              {RUNTIME.HIGHLOW}
            </Button>,
            <Button key="table" size="small">
              <Link to="/data">{RUNTIME.REPORT}</Link>
            </Button>,
            <Button key="draw" size="small">
              <Link to="/data/draw">{RUNTIME.PREVIEW}</Link>
            </Button>,
            <Popover
              isRefresh={isRefresh}
              key="temp"
              placement="bottomRight"
              trigger="click"
              content={() => <DataPopover data={data} />}
            >
              <Button size="small">{RUNTIME.TANDH}</Button>
            </Popover>,
          ]}
        />
      </div>
      <div className="data-body">
        <Switch>
          <Route
            path="/data/draw"
            render={() => (
              <DataDraw data={data} size={size} isRefresh={isRefresh} />
            )}
            exact
          />
          <Route
            path="/data"
            render={() => (
              <DataTable data={data} size={size} isRefresh={isRefresh} />
            )}
          />
        </Switch>
      </div>
      <Modal
        visible={isModal}
        onOk={() => form.submit()}
        onCancel={() => setIsModal(false)}
        title={RUNTIME.HANDLSETTING}
      >
        <Form
          form={form}
          size="small"
          onFinish={(value) => {
            setSetting(value);
            setIsModal(false);
            setIsRefresh(isRefresh + 1);
          }}
        >
          <Form.Item name="temp_alert_max">
            <Input addonBefore={RUNTIME.UPPERLIMIT} addonAfter="°C" />
          </Form.Item>
          <Form.Item name="temp_alert_min">
            <Input addonBefore={RUNTIME.LOWERLIMIT} addonAfter="°C" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
