import React from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import cookie from "react-cookies";
import Login from "./pages/Login";
import Main from "./pages/Main";
import Data from "./pages/Data";
import Setting from "./pages/Setting";

export default () => (
  <Router>
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/setting" exact component={Setting} />
      <Route path="/data" component={Data} />
      <Route
        path="/"
        render={() =>
          cookie.load("Authorization") ? <Main /> : <Redirect to="/login" />
        }
      />
    </Switch>
  </Router>
);
