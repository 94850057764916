import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { getAlterColor } from "../../tools/tool";
import { RUNTIME } from "../../config";

function P1(props) {
  const { x, y, width, height, temp, xie } = props;
  const fontSize = 12;

  return (
    <>
      <polygon
        points={`${x + xie},${y} ${x + xie + width},${y} ${x + width},${
          y + height
        } ${x},${y + height}`}
        stroke="#69c0ff"
        style={{ fill: "#0050b3" }}
      />

      <text
        x={x + (xie + width - fontSize * 2) / 2}
        y={y + fontSize + (height - fontSize * 1.2) / 2}
        fill={
          typeof temp === "string"
            ? temp.indexOf(RUNTIME.CENG) > 0
              ? "#f5222d"
              : "#bae7ff"
            : getAlterColor(temp)
        }
        style={{ fontSize: `${fontSize}px`, fontWeight: "bold" }}
      >
        {temp}
      </text>
    </>
  );
}

export default (props) => {
  if (!props.data) return <Spin />;
  const { base, temp } = props.data;
  const { isRefresh, size } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    dataHandle();
  }, [isRefresh]);

  const width = 64,
    height = 24,
    xie = 16;
  const json_base = JSON.parse(base.data);
  const kuan = parseInt(json_base.宽);
  const chang = parseInt(json_base.长);
  const gao = parseInt(json_base.高);
  const start_x = 12,
    start_y = 12;
  const drawWidth = start_x * 2 + xie * (kuan + 1) + width * (chang + 1);
  const drawHidth =
    start_y * 2 + (kuan + 1) * height * gao + (gao - 1) * height;

  const dataHandle = () => {
    const json_temp = JSON.parse(temp.data);
    const arr = [];
    for (let i = 0; i < gao; i++) {
      for (let j = 0; j < chang + 1; j++)
        for (let k = 0; k < kuan + 1; k++) {
          if (j === 0) {
            //绘制行
            //一个留空
            const text =
              k === 0 ? `${i + 1}${RUNTIME.CENG}` : `${k}${RUNTIME.HANG}`;
            //计算坐标
            const x = start_x + (kuan - k) * xie; //层 * 宽 * 单元格高度
            const y = start_y + (k + i * (kuan + 1 + 1)) * height; //层 * 宽 * 单元格高度
            arr.push({ x, y, text });
          } else {
            //绘制列
            //一个列
            const text =
              k === 0
                ? `${j}${RUNTIME.LIE}`
                : parseFloat(json_temp[`${j * k},${i + 1}`]);
            //计算坐标
            const x = start_x + (kuan - k) * xie + j * width; //层 * 宽 * 单元格高度
            const y = start_y + (k + i * (kuan + 1 + 1)) * height; //层 * 宽 * 单元格高度
            arr.push({ x, y, text });
          }
        }
    }

    setData(arr);
  };

  return (
    <>
      <svg
        style={{
          transform: `scale(${size})`,
          transformOrigin: "0 0",
        }}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width={drawWidth}
        height={drawHidth}
      >
        <rect
          width={drawWidth}
          height={drawHidth}
          style={{ fill: "#002766" }}
        />
        {gao < 2 ? (
          <></>
        ) : (
          <>
            <line
              x1={start_x + (kuan + 1) * xie}
              y1={start_y}
              x2={start_x + (kuan + 1) * xie}
              y2={start_y + (gao - 1) * (kuan + 1 + 1) * height}
              style={{ stroke: "#69c0ff" }}
            />
            <line
              x1={start_x + (kuan + 1) * xie + (chang + 1) * width}
              y1={start_y}
              x2={start_x + (kuan + 1) * xie + (chang + 1) * width}
              y2={start_y + (gao - 1) * (kuan + 1 + 1) * height}
              style={{ stroke: "#69c0ff" }}
            />
          </>
        )}

        {data.map((item, key) => (
          <P1
            key={key}
            x={item.x}
            y={item.y}
            temp={item.text}
            width={width}
            height={height}
            xie={xie}
          />
        ))}
        {gao < 2 ? (
          <></>
        ) : (
          <>
            <line
              x1={start_x}
              y1={start_y + (kuan + 1) * height}
              x2={start_x}
              y2={start_y + (gao * (kuan + 1 + 1) - 1) * height}
              style={{ stroke: "#69c0ff" }}
            />
            <line
              x1={start_x + (chang + 1) * width}
              y1={start_y + (kuan + 1) * height}
              x2={start_x + (chang + 1) * width}
              y2={start_y + (gao * (kuan + 1 + 1) - 1) * height}
              style={{ stroke: "#69c0ff" }}
            />
          </>
        )}
      </svg>
    </>
  );
};
