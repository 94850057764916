const { getSetting, alertColors } = require("./config");

function getAlterColor(val) {
  const temp = parseFloat(val);
  const { temp_alert_max, temp_alert_min } = getSetting();
  const max = temp_alert_max || 30;
  const min = temp_alert_min || 10;
  let percent = 1;
  if (temp > max) percent = alertColors.length - 1;
  else if (temp < min) percent = 0;
  else
    percent =
      parseInt(((temp - min) / (max - min)) * (alertColors.length - 2)) + 1;
  return alertColors[percent];
}

export { getAlterColor };
