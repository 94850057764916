import React, { useEffect, useState } from "react";
import { Spin, Table } from "antd";
import { getAlterColor } from "../../tools/tool";
import { RUNTIME } from "../../config";

export default (props) => {
  if (!props.data) return <Spin />;
  const { base, temp } = props.data;
  const { isRefresh, size } = props;
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    dataHandle();
  }, [isRefresh]);

  const dataHandle = () => {
    const json_base = JSON.parse(base.data);
    const json_temp = JSON.parse(temp.data);

    const 宽 = parseInt(json_base.宽);
    const 长 = parseInt(json_base.长) + 1;
    const 高 = parseInt(json_base.高) + 1;

    //设置表头
    const cols = [];
    for (let i = 0; i < 长; i++) {
      const str = `i${i}`;
      cols.push({
        dataIndex: str,
      });
    }
    setColumns(cols);

    //设置表内容
    const d = [];
    for (let i = 0; i < 宽; i++) {
      for (let j = 0; j < 高; j++) {
        const arr = { i: `${i}${j}` };
        for (let k = 0; k < 长; k++) {
          const lan = 1 + i + (k - 1) * 宽;
          if (j % 高 === 0) {
            //写缆号
            arr[`i${k}`] = k === 0 ? "" : `${lan}${RUNTIME.LAN}`;
          } else {
            //写点号
            arr[`i${k}`] =
              k === 0 ? (
                `${RUNTIME.LEVEL}${j}`
              ) : (
                <b
                  style={{
                    color: getAlterColor(json_temp[`${lan},${j}`]),
                  }}
                >
                  {json_temp[`${lan},${j}`]}
                </b>
              );
          }
        }
        d.push(arr);
      }
    }
    setData(d);
  };
  return (
    <>
      <Table
        style={{
          transform: `scale(${size})`,
          transformOrigin: "0 0",
        }}
        rowClassName="table-color-dust"
        size="small"
        bordered
        dataSource={data}
        columns={columns}
        pagination={false}
        showHeader={false}
        rowKey="i"
      />
    </>
  );
};
