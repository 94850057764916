const URL = "/api/mobile/";
const RUNTIME_EN = {
  SUBCOMPANYLIST: "Subcompany List",
  SETTING: "Setting",
  ABOUTUS: "About Us",
  HELP: "Help",
  ENTER: "Enter",
  DATE: "Date",
  GETNEWDATA: "View New Data",
  DATAVIEW: "Data View",
  ZOOMIN: "ZoomIn",
  ZOOMOUT: "ZoomOut",
  HIGHLOW: "H&L Temp",
  PREVIEW: "Preview",
  REPORT: "Report",
  TANDH: "T&H",
  LAN: "Cable",
  CENG: "F",
  LIE: "Col",
  HANG: "Row",
  SILOLIST: "Silo List",
  TEMPALERT: "Temp Alert",
  UPPERLIMIT: "Upper Limit",
  LOWERLIMIT: "Lower Limit",
  SAVE: "Save",
  LOGOUT: "Logout",
  LANGFANGZHAOSUI: "Zstc Cloud",
  COMPANY: "Company",
  SUBCOMPANY: "Subcompany",
  USERNAME: "Username",
  PASSWORD: "Password",
  LOGIN: "Login",
  LOGINGUEST: "Login As Guest",
  HANDLSETTING: "H&L Temp Setting",
  HIGHTEMP: "Hight Temp",
  LOWTEMP: "Low Temp",
  AVGTEMP: "Avg Temp",
  OUTTEMP: "Out Temp",
  OUTHUM: "Out Hum",
  TIME: "Time",
  LEVEL: "Level",
};
const RUNTIME_ZH = {
  SUBCOMPANYLIST: "子公司列表",
  SETTING: "设置",
  ABOUTUS: "关于我们",
  HELP: "帮助",
  ENTER: "进入",
  DATE: "日期",
  GETNEWDATA: "查看最新数据",
  DATAVIEW: "数据展示",
  ZOOMIN: "放大",
  ZOOMOUT: "缩小",
  HIGHLOW: "高低温",
  PREVIEW: "预览图",
  REPORT: "报&#12288;表",
  TANDH: "T&H",
  TANDH: "温湿度",
  LAN: "缆",
  CENG: "层",
  LIE: "列",
  HANG: "行",
  SILOLIST: "仓列表",
  TEMPALERT: "温度报警",
  UPPERLIMIT: "上限",
  LOWERLIMIT: "下限",
  SAVE: "保存",
  LOGOUT: "注销登录",
  LANGFANGZHAOSUI: "廊坊兆穗在线",
  COMPANY: "总公司",
  SUBCOMPANY: "子公司",
  USERNAME: "用户名",
  PASSWORD: "密码",
  LOGIN: "登录",
  LOGINGUEST: "访客登录",
  HANDLSETTING: "高低温设置",
  HIGHTEMP: "仓高温",
  LOWTEMP: "仓低温",
  AVGTEMP: "仓均温",
  OUTTEMP: "仓外温",
  OUTHUM: "仓外湿",
  TIME: "时间",
  LEVEL: "层",
};
const RUNTIME = RUNTIME_EN;
export { URL, RUNTIME };
