import React, { useEffect, useState } from "react";
import { Button, Dropdown, Menu, PageHeader, Breadcrumb } from "antd";
import { Link, useHistory } from "react-router-dom";
import { MoreOutlined } from "@ant-design/icons";
import request from "../../tools/request";
import { RUNTIME } from "../../config";

export default () => {
  const [name, setName] = useState("...");
  useEffect(() => {
    request("get", "getComName").then((res) => {
      const { code, msg } = res;
      if (code === 200) setName(msg);
    });
  }, []);

  const history = useHistory();
  const [breads, setBreads] = useState([]);

  useEffect(() => {
    setBreads(getBreads());
  }, [history.location.pathname]);

  const getBreads = () => {
    //面包屑
    const list = [
      { path: "/", name: RUNTIME.SUBCOMPANYLIST },
      { path: "/itime", name: RUNTIME.DATE },
      { path: "/cang", name: RUNTIME.SILOLIST },
    ];
    const breads = [];
    for (const bread of list) {
      breads.push(bread);
      if (history.location.pathname === bread.path) break;
    }
    return breads;
  };
  return (
    <PageHeader
      title={name}
      extra={[
        <Dropdown
          key="more"
          trigger={["click"]}
          overlay={
            <Menu>
              <Menu.Item>
                <Link to="/setting">{RUNTIME.SETTING}</Link>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item>{RUNTIME.ABOUTUS}</Menu.Item>
              <Menu.Item>{RUNTIME.HELP}</Menu.Item>
            </Menu>
          }
        >
          <Button type="text" icon={<MoreOutlined />} />
        </Dropdown>,
      ]}
    >
      <Breadcrumb separator=">">
        {breads.map((item, key) => (
          <Breadcrumb.Item key={key}>
            <Link to={item.path}>{item.name}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </PageHeader>
  );
};
