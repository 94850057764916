const getLocalStorage = (name) => {
  try {
    const json = JSON.parse(localStorage.getItem(name));
    if (!json) return {};
    return json;
  } catch (e) {
    return {};
  }
};
const setLocalStorage = (name, obj = {}) =>
  localStorage.setItem(
    name,
    JSON.stringify(Object.assign(getLocalStorage(name), obj))
  );

const getPathInfo = () => getLocalStorage("pathinfo");

const setPathInfo = (obj = {}) => setLocalStorage("pathinfo", obj);

const getSetting = () => getLocalStorage("setting");

const setSetting = (obj = {}) => setLocalStorage("setting", obj);

const alertColors = ["#69c0ff", "#1890ff", "#fadb14", "#ff7875", "#f5222d"];

export { getPathInfo, setPathInfo, getSetting, setSetting, alertColors };
